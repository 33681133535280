import {
  FETCH_ORDERS_RES,
  TOGGLE_ACK_STATUS_REQ,
  TOGGLE_ACK_STATUS_RES_FAILED,
} from '../actions/types';

export default function (state = { data: {}, ids: [] }, action) {
  switch (action.type) {
    case FETCH_ORDERS_RES:
      const newState = { data: {}, ids: [] };
      for (let order of action.payload) {
        newState.data[order['order-num']] = order;
        newState.ids.push(order['order-num']);
      }

      return newState;

    case TOGGLE_ACK_STATUS_REQ:
    case TOGGLE_ACK_STATUS_RES_FAILED:
      const { orderNum, update } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [orderNum]: { ...state.data[orderNum], 'edi-855-count': update },
        },
      };

    default:
      return state;
  }
}
