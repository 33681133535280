import React, { Component } from 'react';
import requireAuth from './requireAuth';
import SearchBar from './SearchBar';
import NordstromInfo from './NordstromInfo';
import { connect } from 'react-redux';
import { fetchNordstromOrdersPrecheck } from '../actions';

class Nordstrom extends Component {
  render() {
    return (
      <div>
        <SearchBar
          placeholder="Nordstrom Order Numbers"
          onSearchClick={this.props.fetchNordstromOrdersPrecheck}
        />
        <NordstromInfo />
      </div>
    );
  }
}

export default connect(null, { fetchNordstromOrdersPrecheck })(
  requireAuth(Nordstrom)
);
