import React, { Component } from 'react';
// import M from 'materialize-css';

const options = [
  ['ashley', 'Ashley'],
  ['atg', ''],
  ['bedbath', 'Bed Bath & Beyond'],
  ['bellacor', 'Bellacor'],
  ['benzara', ''],
  ['brandjump', ''],
  ['christmascentral', ''],
  ['costco', 'Costco'],
  ['costplus', 'Costplug'],
  ['decorsteals', ''],
  ['fantasticdecor', ''],
  ['gilt', 'Gilt'],
  ['gwgoutlet', ''],
  ['hayneedle', 'Hayneedle'],
  ['hillcountry', ''],
  ['houzz', 'Houzz'],
  ['inventoryforecommercecustomer', ''],
  ['jordans', ''],
  ['kirklands', 'Kirklands'],
  ['kohls', 'Kohls'],
  ['lowes', 'Lowes'],
  ['macys', 'Macys'],
  ['ojcommerce', 'Ojcommerce'],
  ['omt', ''],
  ['omtwarehouse', ''],
  ['overstock', 'Overstock'],
  ['qvc', 'QVC'],
  ['realdeals', ''],
  ['rtg', 'RTG'],
  ['rtglighting', 'RTG Lighting'],
  ['ssi', ''],
  ['target', 'Target'],
  ['thehomedepot', 'Home Depot'],
  ['unbeatablesale', ''],
  ['walmart', 'Walmart'],
  ['wayfair', 'Wayfair'],
  ['wayfairSA', 'Wayfair SA'],
  ['zebit', 'Zebit'],
  ['zola', 'Zola'],
];

class InventorySearchBar extends Component {
  constructor() {
    super();
    this.state = { account: '', searchTerm: '' };
    this.onInputChange = this.onInputChange.bind(this);
    this.onAccountChange = this.onAccountChange.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  onInputChange(event) {
    this.setState({ searchTerm: event.target.value });
  }

  onAccountChange(event) {
    this.setState({ account: event.target.value });
  }

  onButtonClick(event) {
    event.preventDefault();
    this.props.onSearchClick(this.state.account, this.state.searchTerm);
    this.setState({ account: '', searchTerm: '' });
  }

  // componentDidMount() {
  //   let selects = document.querySelectorAll('select');
  //   M.FormSelect.init(selects, {});
  // }

  render() {
    return (
      <div className="row">
        <form onSubmit={this.onButtonClick}>
          <div className="input-field col m2 s4">
            <select
              className="browser-default"
              onChange={this.onAccountChange}
              value={this.state.account}
            >
              <option value="" disabled>
                Choose an account
              </option>
              {options
                .filter(([, name]) => name)
                .map(([value, name]) => {
                  return (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="input-field col m8 s4">
            <input
              placeholder={this.props.placeholder}
              type="text"
              onChange={this.onInputChange}
              value={this.state.searchTerm}
            />
          </div>
          <div className="input-field col m2 s4">
            <button className="btn right">Search</button>
          </div>
        </form>
      </div>
    );
  }
}

export default InventorySearchBar;
