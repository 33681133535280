import React, { Component } from 'react';
import Loading from './Loading';
import { connect } from 'react-redux';

class NordstromInfo extends Component {
  renderPrecheck() {
    const precheckFields = [
      { name: 'num-of-submitted', desc: '# of orders submitted' },
      { name: 'num-of-returned', desc: '# of submitted orders returned' },
      { name: 'num-of-closed', desc: '# of returned orders closed' },
      {
        name: 'num-of-unique-po-dc',
        desc: '# of unique returned orders (returned orders that are per PO per DC)',
      },
    ];

    const {
      nordstromOrdersPrecheck: { PRECHECK },
    } = this.props;

    if (Object.entries(PRECHECK).length === 0) {
      return <li>precheck not found</li>;
    } else {
      return precheckFields.map(({ name, desc }) => {
        return (
          <li>
            <strong>{desc}: </strong>
            {PRECHECK[name]}
          </li>
        );
      });
    }
  }

  renderHeaders() {
    return [
      'Nordstrom Order',
      'Closed',
      'Apprise Order#',
      'Apprise Cust-PO#',
      'Apprise Order Status',
      'Apprise Last Updated Date',
      'PO#',
      'DC#',
      'BOL#',
    ].map((header) => {
      return <th key={header}>{header}</th>;
    });
  }

  rednerDetails() {
    const {
      nordstromOrdersPrecheck: { DETAILS },
    } = this.props;

    return (
      <table className="centered highlight responsive-table">
        <thead>
          <tr>{this.renderHeaders()}</tr>
        </thead>
        <tbody>
          {DETAILS.map((detail) => {
            return (
              <tr key={detail['order-num']}>
                <td>{detail.analysis.nordstromPo ? 'Yes' : 'No'}</td>
                <td>{detail.analysis.closed ? 'Yes' : 'No'}</td>
                <td>{detail['order-num']}</td>
                <td>{detail['cust-po-number']}</td>
                <td>{detail['order-status']}</td>
                <td>{detail['update-date']}</td>
                <td>{detail.analysis['po-num']}</td>
                <td>{detail.analysis.dc}</td>
                <td>{detail['user-defined-10']}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    if (!this.props.nordstromOrdersPrecheck) {
      return (
        <div>
          <h3>Enter a list of Nordstrom orders separated by commas</h3>
          <h6>
            e.g.
            USV000012505,USV000012506,USV000012507,USV000012513,USV000012532,USV000012537
          </h6>
        </div>
      );
    }

    return (
      <div class="row">
        <div class="col s12">
          <div class="card light-blue">
            <div class="card-content white-text left-align">
              <span class="card-title">Nordstrom Orders Precheck</span>
              <ul>{this.renderPrecheck()}</ul>
              <span>
                *All numbers must match for the system to generate the ASNs!
              </span>
            </div>
          </div>
        </div>
        <div class="col s12">{this.rednerDetails()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    nordstromOrdersPrecheck: state.nordstromOrdersPrecheck,
    loading: state.loading.fetch_nordstromOrdersPrecheck,
  };
}

export default connect(mapStateToProps)(NordstromInfo);
