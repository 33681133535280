import React, { Component } from 'react';
import requireAuth from './requireAuth';
import SearchBar from './SearchBar';
import ProductInfo from './ProductInfo';
import { connect } from 'react-redux';
import { fetchProduct } from '../actions';

class ProductLookup extends Component {
  render() {
    return (
      <div>
        <SearchBar
          placeholder="A&B Sku Number"
          onSearchClick={this.props.fetchProduct}
        />
        <ProductInfo />
      </div>
    );
  }
}

export default connect(
  null,
  { fetchProduct }
)(requireAuth(ProductLookup));
