import React, { Component } from 'react';
import requireAuth from './requireAuth';
import InventorySearchBar from './InventorySearchBar';
import InventoryInfo from './InventoryInfo';
import { connect } from 'react-redux';
import { fetchInventory } from '../actions';

class InventoryLookup extends Component {
  render() {
    return (
      <div>
        <InventorySearchBar
          placeholder="A&B Sku Number"
          onSearchClick={this.props.fetchInventory}
        />
        <InventoryInfo />
      </div>
    );
  }
}

export default connect(null, { fetchInventory })(requireAuth(InventoryLookup));
