import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Sidenav from './components/Sidenav';
import Welcome from './components/Welcome';
import OrderList from './components/OrderList';
import CustomerLookup from './components/CustomerLookup';
import ProductLookup from './components/ProductLookup';
import InventoryLookup from './components/InventoryLookup';
import Nordstrom from './components/Nordstrom';

import Signin from './components/auth/Signin';
import Signout from './components/auth/Signout';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Sidenav />
        <Header />
        <main>
          <div className="container center-align">
            <Route path="/" exact component={Welcome} />
            <Route path="/signin" exact component={Signin} />
            <Route path="/signout" exact component={Signout} />
            <Route path="/order-list" exact component={OrderList} />
            <Route path="/customer-lookup" exact component={CustomerLookup} />
            <Route path="/product-lookup" exact component={ProductLookup} />
            <Route path="/inventory-lookup" exact component={InventoryLookup} />
            <Route path="/nordstrom" exact component={Nordstrom} />
          </div>
        </main>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default App;
