import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from './Loading';

class InventoryInfo extends Component {
  renderHeaders() {
    return ['SKU #', 'Description', 'Qty'].map((header) => {
      return <th key={header}>{header}</th>;
    });
  }
  render() {
    if (this.props.loading) {
      return (
        <div style={{ marginTop: '2.5rem' }}>
          <Loading />
        </div>
      );
    } else if (this.props.inventory.length === 0) {
      return <h3>Choose an account and type in sku numbers</h3>;
    } else {
      return (
        <table className="centered highlight responsive-table">
          <thead>
            <tr>{this.renderHeaders()}</tr>
          </thead>
          <tbody>
            {this.props.inventory.map((sku) => {
              return (
                <tr key={sku.PRODUCTCODE}>
                  <td>{sku.PRODUCTCODE}</td>
                  <td>{sku.PRODUCTNAME}</td>
                  <td>{sku.TQOH}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  }
}

function mapStateToProps(state) {
  return { inventory: state.inventory, loading: state.loading.fetch_inventory };
}

export default connect(mapStateToProps)(InventoryInfo);
