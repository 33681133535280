import React, { Component } from 'react';
import validateEmail from '../services/validateEmail';
import Loading from './Loading';

export default class CustomerInfo extends Component {
  renderApprise(apprise) {
    if (apprise.message) {
      return (
        <ul>
          <li>{apprise.message}</li>
        </ul>
      );
    }

    return (
      <ul>
        <li>
          <strong>CUSTOMER CODE: </strong>
          {apprise['cust-code']}
        </li>
        <li>
          <strong>COMPANY: </strong>
          {apprise.Company}
        </li>
        <li>
          <strong>RETAILER: </strong>
          {apprise.Retailer}
        </li>
        <li>
          <strong>USER EMAIL: </strong>
          {apprise['email-address']}
        </li>
        <li>
          <strong>PRICE BOOK CODE: </strong>
          {apprise['price-book-name']}
        </li>
        <li>
          <strong># OF EMAIL CHARS: </strong>
          {apprise['email-num-chars']}
        </li>
        <li>
          <strong>EMAIL VALIDATION: </strong>
          {validateEmail(apprise['email-address']) ? 'valid' : 'invalid'}
        </li>
        <li>
          <strong>ACCOUNT UPDATED DATE: </strong>
          {apprise['update-date']} {apprise['update-time']}
        </li>
      </ul>
    );
  }

  renderWebsite(website) {
    if (website.message) {
      return (
        <ul>
          <li>{website.message}</li>
        </ul>
      );
    }

    return (
      <ul>
        <li>
          <strong>CUSTOMER CODE: </strong> {website.customerCode}
        </li>
        <li>
          <strong>USER LOGIN: </strong>
          {website.userLogin}
        </li>
        <li>
          <strong>USER EMAIL: </strong>
          {website.userEmail}
        </li>
        <li>
          <strong>PRICE BOOK CODE: </strong>
          {website.priceBook}
        </li>
        <li>
          <strong>FIRST SYNC'D DATE: </strong>
          {website.firstSyncTime}
        </li>
        <li>
          <strong>LAST SYNC'D DATE: </strong>
          {website.lastSyncTime}
        </li>
        <li>
          <strong>LAST RESET PASSWORD: </strong>
          {website.lastResetPassword}
        </li>
      </ul>
    );
  }

  render() {
    const { loading, apprise, ABRes, SARes } = this.props.custData;

    if (loading) {
      return <Loading />;
    } else {
      if (!apprise && !ABRes && !SARes) {
        return <h3>Enter customer code or email first</h3>;
      } else {
        return (
          <div class="row">
            <div class="col s12 m6">
              <div class="card indigo">
                <div class="card-content white-text left-align">
                  <span class="card-title">Apprise</span>
                  {this.renderApprise(apprise)}
                </div>
              </div>
            </div>
            <div class="col s12 m6">
              <div class="card grey darken-1">
                <div class="card-content white-text left-align">
                  <span class="card-title">abhomeinc.com</span>
                  {this.renderWebsite(ABRes)}
                </div>
              </div>
              <div class="card grey darken-1">
                <div class="card-content white-text left-align">
                  <span class="card-title">seasonalabode.com</span>
                  {this.renderWebsite(SARes)}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}
