import React, { Component } from 'react';
import requireAuth from './requireAuth';
import SearchBar from './SearchBar';
import CustomerInfo from './CustomerInfo';
import axios from 'axios';
import validateEmail from '../services/validateEmail';

class CustomerLookup extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, apprise: null, ABRes: null, SARes: null };
    this.termSearch = this.termSearch.bind(this);
  }

  async termSearch(term) {
    let queryString;
    if (validateEmail(term)) {
      queryString = `?email=${term}`;
    } else {
      queryString = `?code=${term}`;
    }

    this.setState({ loading: true, apprise: null, ABRes: null, SARes: null });

    const response = await axios.get(`/api/customers${queryString}`);

    this.setState({
      loading: false,
      apprise: response.data.apprise,
      ABRes: response.data.ABRes,
      SARes: response.data.SARes,
    });
  }

  render() {
    return (
      <div>
        <SearchBar
          placeholder="Customer Code/Email"
          onSearchClick={this.termSearch}
        />
        <CustomerInfo custData={this.state} />
      </div>
    );
  }
}

export default requireAuth(CustomerLookup);
