import React, { Component } from 'react';

export default class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = { searchTerm: '' };
    this.onInputChange = this.onInputChange.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  onInputChange(event) {
    this.setState({ searchTerm: event.target.value });
  }

  onButtonClick() {
    this.props.onSearchClick(this.state.searchTerm);
    this.setState({ searchTerm: '' });
  }

  render() {
    return (
      <div className="row">
        <div className="input-field col m10 s8">
          <input
            placeholder={this.props.placeholder}
            type="text"
            onChange={this.onInputChange}
            value={this.state.searchTerm}
          />
        </div>

        <div className="input-field col m2 s4">
          <button onClick={this.onButtonClick} className="btn right">
            Search
          </button>
        </div>
      </div>
    );
  }
}
