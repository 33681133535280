import React, { Component } from 'react';
import requireAuth from './requireAuth';
import Order from './Order';
import Loading from './Loading';
import { connect } from 'react-redux';
import { fetchOrders, toggleAckStatus } from '../actions';

class OrderList extends Component {
  componentDidMount() {
    this.props.fetchOrders();
  }

  renderHeaders() {
    return [
      'Customer',
      'Status',
      'Apprise Order#',
      'Customer PO#',
      '855 Ack Status',
      'Date Created',
      'Time Created',
      'Created By',
      'Date Updated',
      'Time Updated',
      'Updated By',
    ].map((header) => {
      return <th key={header}>{header}</th>;
    });
  }

  getToday() {
    const today = new Date();
    const dd = `0${today.getDate()}`.slice(-2);
    const mm = `0${today.getMonth() + 1}`.slice(-2);
    const yyyy = today.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
  }

  render() {
    if (this.props.loading) {
      return (
        <div style={{ marginTop: '2.5rem' }}>
          <Loading />
        </div>
      );
    }

    return (
      <table className="centered highlight responsive-table">
        <thead>
          <tr>{this.renderHeaders()}</tr>
        </thead>
        <tbody>
          {this.props.ids.map((id) => {
            // return <Order key={id} id={id} today={this.getToday()} />;
            return <Order key={id} id={id} />;
          })}
        </tbody>
      </table>
    );
  }
}

function mapStateToProps(state) {
  return {
    ids: state.orders.ids,
    loading: state.loading.fetch_orders,
  };
}

export default connect(mapStateToProps, { fetchOrders, toggleAckStatus })(
  requireAuth(OrderList)
);
