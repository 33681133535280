import {
  AUTH_USER,
  AUTH_ERROR,
  FETCH_ORDERS_REQ,
  FETCH_ORDERS_RES,
  FETCH_PRODUCT_REQ,
  FETCH_PRODUCT_RES,
  TOGGLE_SIDENAV,
  TOGGLE_ACK_STATUS_REQ,
  TOGGLE_ACK_STATUS_RES_FAILED,
  FETCH_INVENTORY_REQ,
  FETCH_INVENTORY_RES,
  FETCH_NORDSTROMORDERSPRECHECK_REQ,
  FETCH_NORDSTROMORDERSPRECHECK_RES,
} from './types';
import axios from 'axios';
import bcrypt from 'bcryptjs';
import credentials from '../config/credentials';

export const signin =
  ({ email, password }, callback) =>
  async (dispatch) => {
    if (
      credentials[email] &&
      bcrypt.compareSync(password, credentials[email])
    ) {
      dispatch({ type: AUTH_USER, payload: credentials[email] });
      localStorage.setItem('token', credentials[email]);
      callback();
    } else {
      dispatch({ type: AUTH_ERROR, payload: 'Invalid login credentials' });
    }
  };

export const signout = () => {
  localStorage.removeItem('token');

  return {
    type: AUTH_USER,
    payload: '',
  };
};

export const fetchOrders = () => async (dispatch) => {
  dispatch({ type: FETCH_ORDERS_REQ });

  const orders = await axios
    .get('api/apprise/edi-trading-partners/orders')
    .then((res) => res.data);

  dispatch({
    type: FETCH_ORDERS_RES,
    payload: orders,
  });
};

export const fetchProduct = (term) => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCT_REQ });

  const product = await axios
    .get(`api/apprise/products/${term}`)
    .then((res) => res.data);

  dispatch({
    type: FETCH_PRODUCT_RES,
    payload: product,
  });
};

export const toggleSidenav = (status) => {
  return {
    type: TOGGLE_SIDENAV,
    payload: status,
  };
};

export const toggleAckStatus =
  (custName, orderNum, edi855Count) => async (dispatch) => {
    const update = edi855Count !== 0 ? 0 : 1;

    dispatch({
      type: TOGGLE_ACK_STATUS_REQ,
      payload: { custName, orderNum, update },
    });

    let result;

    if (!update) {
      result = await axios
        .delete(`api/apprise/edi/orders/${orderNum}/ack`)
        .then((res) => res.data);
    } else {
      result = await axios
        .put(`api/apprise/edi/orders/${orderNum}/ack`)
        .then((res) => res.data);
    }

    if (result !== 'OK') {
      dispatch({
        type: TOGGLE_ACK_STATUS_RES_FAILED,
        payload: { custName, orderNum, update: !update ? 1 : 0 },
      });
    }
  };

export const fetchInventory = (account, skus) => async (dispatch) => {
  dispatch({ type: FETCH_INVENTORY_REQ });

  const inventory = await axios
    .get(`api/apprise/edi/${account}/inventory?skus=${skus}`)
    .then((res) => res.data);

  dispatch({
    type: FETCH_INVENTORY_RES,
    payload: inventory.DATA ? inventory.DATA.PRODUCTS : [],
  });
};

export const fetchNordstromOrdersPrecheck = (orderNums) => async (dispatch) => {
  dispatch({ type: FETCH_NORDSTROMORDERSPRECHECK_REQ });

  const ordersPreCheck = await axios
    .get(`api/apprise/nordstromAuto?orderNum=${orderNums}`)
    .then((res) => res.data);

  dispatch({
    type: FETCH_NORDSTROMORDERSPRECHECK_RES,
    payload: ordersPreCheck,
  });
};
