import React from 'react';

export default () => {
  return (
    <footer className="page-footer">
      <div className="footer-copyright">
        <div className="container">© 2019 Jengo LLC, All rights reserved.</div>
      </div>
    </footer>
  );
};
