const INITIAL_STATE = {
  fetch_product: false,
  fetch_orders: false,
  fetch_inventory: false,
  fetch_nordstromOrdersPrecheck: false,
};
const prefixes = Object.keys(INITIAL_STATE).join('|');
const regex = new RegExp(`(${prefixes})_(req|res)`);
export default function (state = INITIAL_STATE, action) {
  const matches = action.type.match(regex);

  if (!matches) return state;

  const [, reqName, res] = matches;
  return { ...state, [reqName]: res === 'req' };
}
