import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signout } from '../../actions';

class Signout extends Component {
  componentDidMount() {
    this.props.signout();
  }
  render() {
    return <h5>Goodbye!</h5>;
  }
}

export default connect(
  null,
  { signout }
)(Signout);
