import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleSidenav } from '../actions';

class Header extends Component {
  renderLinks() {
    const authedLinks = [
      { link: '/order-list', desc: 'Dropship Orders' },
      { link: '/customer-lookup', desc: 'Customer Lookup' },
      { link: '/product-lookup', desc: 'Product Lookup' },
      { link: '/inventory-lookup', desc: 'Inventory Lookup' },
      { link: '/signout', desc: 'Sign Out' },
    ];

    if (this.props.authenticated) {
      let key = 1;
      return authedLinks.map(({ link, desc }) => {
        return (
          <li key={key++}>
            <Link to={link}>{desc}</Link>
          </li>
        );
      });
    } else {
      return (
        <li>
          <Link key="0" to="/signin">
            Sign In
          </Link>
        </li>
      );
    }
  }

  render() {
    return (
      <div>
        <ul id="slide-out" className="sidenav">
          {this.renderLinks(true)}
        </ul>
        <header className="navbar-fixed">
          <nav>
            <div className="nav-wrapper container">
              <Link to="/" className="brand-logo">
                Dev Portal
              </Link>
              <div
                id="sidenav-button"
                onClick={() => this.props.toggleSidenav(!this.props.sidenav)}
                className="sidenav-trigger"
              >
                <i className="material-icons">menu</i>
              </div>
              <ul id="nav-mobile" className="right hide-on-med-and-down">
                {this.renderLinks()}
              </ul>
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { authenticated: state.auth.authenticated, sidenav: state.sidenav };
}

export default connect(mapStateToProps, { toggleSidenav })(Header);
