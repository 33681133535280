import React from 'react';

export default ({
  input,
  id,
  label,
  name,
  autoComplete,
  type,
  meta: { active, dirty }
}) => {
  console.log(dirty);
  return (
    <div className="row">
      <div className="input-field col s10 offset-s1 m4 offset-m4">
        <i class="material-icons prefix">
          {id === 'name' ? 'account_circle' : 'lock'}
        </i>
        <input
          id={id}
          type={type}
          name={name}
          autoComplete={autoComplete}
          {...input}
        />
        <label htmlFor={id} className={active || dirty ? 'active' : null}>
          {label}
        </label>
      </div>
    </div>
  );
};
