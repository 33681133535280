export const AUTH_USER = 'auth_user';
export const AUTH_ERROR = 'auth_error';
export const FETCH_ORDERS_REQ = 'fetch_orders_req';
export const FETCH_ORDERS_RES = 'fetch_orders_res';
export const FETCH_PRODUCT_REQ = 'fetch_product_req';
export const FETCH_PRODUCT_RES = 'fetch_product_res';
export const TOGGLE_SIDENAV = 'toggle_sidenav';
export const TOGGLE_ACK_STATUS_REQ = 'toggle_ack_status_req';
export const TOGGLE_ACK_STATUS_RES_FAILED = 'toggle_ack_status_res_failed';
export const FETCH_INVENTORY_REQ = 'fetch_inventory_req';
export const FETCH_INVENTORY_RES = 'fetch_inventory_res';
export const FETCH_NORDSTROMORDERSPRECHECK_REQ =
  'fetch_nordstromordersprecheck_req';
export const FETCH_NORDSTROMORDERSPRECHECK_RES =
  'fetch_nordstromordersprecheck_res';
