import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleAckStatus } from '../actions';

class Order extends Component {
  constructor() {
    super();
    this.handleToggleAckStatus = this.handleToggleAckStatus.bind(this);
  }

  handleToggleAckStatus() {
    const { order } = this.props;
    this.props.toggleAckStatus(
      order['cust-name'],
      order['order-num'],
      order['edi-855-count']
    );
  }

  render() {
    const { order } = this.props;

    const statusBadgeColorCss = {
      Voided: 'pink pink-text',
      Open: 'green green-text',
      'In Pick': 'purple purple-text',
      Confirmed: 'indigo indigo-text',
      Closed: 'blue blue-text',
    };

    return (
      <tr key={order['order-num']}>
        <td>{order['cust-name']}</td>
        <td>
          <span
            className={`badge lighten-5 text-accent-2 ${
              statusBadgeColorCss[order['order-status']]
            }`}
          >
            {order['order-status']}
          </span>
        </td>
        <td>{order['order-num']}</td>
        <td>{order['cust-po-number']}</td>
        <td>
          <i
            className="material-icons prefix ack-toggle"
            onClick={this.handleToggleAckStatus}
          >
            {order['edi-855-count'] === 1
              ? 'check_box'
              : 'check_box_outline_blank'}
          </i>
        </td>
        <td>{order['created-date']}</td>
        <td>{order['created-time']}</td>
        <td>{order['created-by']}</td>
        <td>{order['update-date']}</td>
        <td>{order['update-time']}</td>
        <td>{order['update-by']}</td>
      </tr>
    );
  }
}

function mapStateToProps(_, initialProps) {
  const { id } = initialProps;
  return (state) => {
    return {
      order: state.orders.data[id],
    };
  };
}

export default connect(mapStateToProps, { toggleAckStatus })(Order);
