import React, { Component } from 'react';
import Loading from './Loading';
import { connect } from 'react-redux';

class ProductInfo extends Component {
  renderProductFields() {
    const productFields = [
      { name: 'PRODUCTCODE', desc: 'SKU Number' },
      { name: 'PRODUCTDESCRIPTION', desc: 'Description' },
      { name: 'LVL1CATEGORY', desc: 'Lvl1 Category' },
      { name: 'LVL2CATEGORY', desc: 'Lvl2 Category' },
      { name: 'LVL3CATEGORY', desc: 'Lvl3 Category' },
      { name: 'WEBSITEFLAG', desc: 'Website Flag' },
      { name: 'DISCONTINUED', desc: 'Discontinued' },
      {
        name: 'UWLAVAILQTY',
        desc: 'WL AvailQty (qoh - qpending)',
      },
      {
        name: 'C00AVAILQTY',
        desc: 'Xiamen AvailQty (qoh - qpending - qnav)',
      },
      {
        name: 'S0AAVAILQTY',
        desc: 'SA RC AvailQty (qoh - qpending)',
      },
      {
        name: 'S0BAVAILQTY',
        desc: 'SA SH AvailQty (qoh - qpending)',
      },
      {
        name: 'SECDISCOUNT',
        desc: 'Additional Discount Cust Groups',
      },
      {
        name: 'ABPRICE',
        desc: 'abhome.com Price',
      },
      {
        name: 'DMPRICE',
        desc: 'designermaison.com Price',
      },
      {
        name: 'CDMPRICE',
        desc: 'abhome-china.com Price',
      },
      {
        name: 'NONSABREAKPRICES',
        desc: 'AB, DM, CDM Volume Price Breaks',
      },
      {
        name: 'NONSABREAKQTYS',
        desc: 'AB, DM, CDM Volume Qty Breaks',
      },

      {
        name: 'SABREAKPRICES',
        desc: 'SA Volume Price Breaks',
      },
      {
        name: 'SABREAKQTYS',
        desc: 'SA Volume Qty Breaks',
      },
    ];

    const { product } = this.props;

    if (Object.entries(product).length === 0) {
      return <li>product not found</li>;
    } else {
      return productFields.map(({ name, desc }) => {
        return (
          <li>
            <strong>{desc}: </strong>
            {product[name]}
          </li>
        );
      });
    }
  }

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    if (!this.props.product) {
      return <h3>Enter A&B sku number first</h3>;
    }

    return (
      <div class="row">
        <div class="col s12">
          <div class="card light-blue">
            <div class="card-content white-text left-align">
              <span class="card-title">Apprise Product Detail</span>
              <ul>{this.renderProductFields()}</ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { product: state.product, loading: state.loading.fetch_product };
}

export default connect(mapStateToProps)(ProductInfo);
