import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import orders from './orders';
import product from './product';
import inventory from './inventory';
import loading from './loading';
import sidenav from './sidenav';
import nordstromOrdersPrecheck from './nordstromOrdersPrecheck';

export default combineReducers({
  auth,
  form: formReducer,
  orders,
  product,
  loading,
  inventory,
  sidenav,
  nordstromOrdersPrecheck,
});
